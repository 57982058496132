<template>
  <div class="teamee">
    <AppHeader v-if="$route.name === 'Home' || $route.name === 'SocialHub' || $route.name === 'Teamee' || $route.name === 'Profile'"></AppHeader>
    <CookieBanner v-if="!acceptCookies && $route.name !== 'Legal'"></CookieBanner>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <MobileNavBar
      v-if="$route.name === 'Home' || $route.name === 'SocialHub' || $route.name === 'Teamee' || $route.name === 'Profile'"
      v-show="socialHubComponent !== 'Chat' && teameeComponent !== 'TeamChat'"
    ></MobileNavBar>
  </div>
</template>

<script>
import CookieBanner from "@/components/CookieBanner.vue";
import AppHeader from "@/components/AppHeader.vue";
import MobileNavBar from "@/components/MobileNavBar.vue";

export default {
  components: {
    CookieBanner,
    AppHeader,
    MobileNavBar,
  },
  methods: {
    checkIfUserAcceptedCookies() {
      if (document.cookie.indexOf("acceptCookies") > -1) {
        this.$store.commit("SET_COOKIES", true); // User has accepted Cookies
      } else {
        this.$store.commit("SET_COOKIES", false);
      }
    },
    checkIfUserAcceptedGACookies() {
      if (document.cookie.indexOf("acceptGACookies") > -1) {
        this.$store.commit("SET_GA_COOKIES", true); // User has accepted Google Analytics Cookies
      } else {
        this.$store.commit("SET_GA_COOKIES", false);
      }
    },
  },

  // refresh Friend Requests and Team Data, when User reloads page
  mounted() {
    if (localStorage.getItem("auth") == "true") {
      this.$store.dispatch("getFriendRequests");
      this.$store.dispatch("getChatRooms");
      this.$store.dispatch("getMyTeam");
      this.$store.dispatch("receiveNotifications");
      this.$store.dispatch("getBlockedUsers");
    }
    this.checkIfUserAcceptedCookies();
    this.checkIfUserAcceptedGACookies();
  },
  computed: {
    socialHubComponent() {
      return this.$store.getters.SOCIALHUBCOMPONENT;
    },
    teameeComponent() {
      return this.$store.getters.TEAMEECOMPONENT;
    },
    acceptCookies() {
      return this.$store.getters.COOKIES;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_globals.scss";

// Font Family: Rubik

@font-face {
  font-family: "Rubik-Regular";
  src: url("assets/fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Light";
  src: url("assets/fonts/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Bold";
  src: url("assets/fonts/Rubik-Bold.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $white;
  font-family: "Rubik-Regular";
}

a {
  display: inline-block;
  width: fit-content;
  text-decoration: none;
}

body,
#app {
  background: $navy-blue;
}

// Custom Scrollbar Styling

// Firefox
html {
  scrollbar-color: $orange transparent;
  scrollbar-width: thin;
  @media screen and (max-width: 960px) {
    scrollbar-color: $orange $dark-navy-blue;
  }
}

// Chrome and other Webkit Browser
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  @media screen and (max-width: 960px) {
    background: $dark-navy-blue;
  }
}
::-webkit-scrollbar-thumb {
  background: $gradient_vertical;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: $orange;
}
</style>
