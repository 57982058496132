<template>
  <div class="register_login">
    <main>
      <div class="content">
        <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee" />

        <div class="login-register">
          <form class="login" action="#" method="post" v-on:submit.prevent="login">
            <h3>Login</h3>
            <input
              class="input__text__rounded"
              :class="{ input__text__rounded__danger: $v.nameOrMail.$error || errorMsg }"
              type="text"
              placeholder="Username oder E-Mail"
              title="Username oder E-Mail"
              v-model.trim="$v.nameOrMail.$model"
            />
            <input
              class="input__text__rounded"
              :class="{ input__text__rounded__danger: $v.password.$error || errorMsg }"
              type="password"
              placeholder="Passwort"
              title="Passwort"
              v-model.trim="$v.password.$model"
            />

            <button class="cta button__cta" :disabled="isLoading">
              <span class="loader" v-if="isLoading"></span>
              <span class="txt" v-else>Login</span>
            </button>

            <router-link class="xs" to="/ForgotPassword-1">Passwort vergessen?</router-link>

            <div v-if="errorMsg" class="error">
              <li class="error-msg">{{ errorMsg }}</li>
              <div class="resend-email" v-if="resendEmail">
                <p>Keine Email erhalten?</p>
                <p class="resend" @click="openResendEmailComponent">E-Mail erneut senden</p>
              </div>
            </div>
          </form>

          <span class="divider"></span>

          <div class="register">
            <h3>Du bist neu hier?</h3>
            <router-link class="btn-register" to="/Register">Jetzt Account erstellen</router-link>
          </div>
        </div>
      </div>
    </main>
    <ResendEmailInfobox v-if="showResendEmailComponent"></ResendEmailInfobox>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";
import ResendEmailInfobox from "@/components/ResendEmailInfobox.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RegisterAndLogin",
  components: {
    AppFooterRegistration,
    ResendEmailInfobox,
  },
  data() {
    return {
      nameOrMail: "",
      password: "",
      isLoading: false,
      errorMsg: "",
      resendEmail: false,
    };
  },
  validations: {
    nameOrMail: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if Form is valid -> submit Form
        this.isLoading = true;

        // make Api Request and wait for Response
        this.$store
          .dispatch("login", {
            nameOrMail: this.nameOrMail,
            password: this.password,
          })
          .then(async (response) => {
            localStorage.setItem("auth", "true");
            this.$store.commit("SET_USER", response.data);
            await Promise.allSettled([
              this.$store.dispatch("receiveNotifications"),
              this.$store.dispatch("getChatRooms"),
              this.$store.dispatch("getTeameeRequests").then((response) => this.$store.commit("SET_TEAMEE_REQUESTS", response.data)),
              this.$store.dispatch("getMyTeam"),
              this.$store.dispatch("getMyGames"),
              this.$store.dispatch("getFriendRequests"),
              this.$store.dispatch("getBlockedUsers"),
            ])
              .then(() => {
                this.isLoading = false;
                this.$router.push("Home");
              })
              .catch(() => {
                this.isLoading = false;
              });
          })

          .catch((error) => {
            this.isLoading = false;
            // Wrong Credentials
            if (error.response.status === 422) {
              this.errorMsg = error.response.data.errors.errorMsg[0];
            }
            // Email not verified
            if (error.response.status === 403) {
              this.errorMsg = error.response.data.message;
              this.resendEmail = true;
            }
            // Invalid/Expired Url
            if (error.response.status === 401) {
              this.errorMsg = error.response.data.message;
              this.resendEmail = true;
            }
          });
      }
    },
    openResendEmailComponent() {
      this.$store.state.showResendEmailComponent = true;
    },
  },
  computed: {
    showResendEmailComponent() {
      return this.$store.state.showResendEmailComponent;
    },
  },
  mounted() {
    // clear VUEX Store after User logs out
    // to prevent wrong Game Data from beeing displayed
    // when different User logs in
    if (localStorage.getItem("auth") === null) {
      this.$store.state.myGames = [];
      this.$store.state.teamee_requests = [];
      this.$store.state.friend_request = [];
      this.$store.state.myFriends = [];
      this.$store.state.myChatRooms = [];
      this.$store.state.homeComponent = "Home-Requests";
      this.$store.state.registerComponent = "Register1";
      this.$store.state.socialHubComponent = "AllChatRooms";
      this.$store.state.profileComponent = "Profile-Home";
      this.$store.state.teameeComponent = "Teamee1";
      this.$store.state.tosComponent = "LegalNotice";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 960px) {
      align-self: flex-end;
      width: 100%;
      max-width: 600px;
    }
    .logo__large {
      margin-bottom: 80px;
      @media screen and (max-width: 960px) {
        top: 20px;
        position: fixed;
      }
    }
    .login-register {
      display: flex;
      @media screen and (max-width: 960px) {
        flex-direction: column;
        width: 100%;
        padding: 0 24px;
      }
      .login {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 960px) {
          margin-bottom: 56px;
        }
        h3,
        .input__text__rounded {
          margin-bottom: 20px;
        }
        h3 {
          text-align: center;
          @media screen and (max-width: 960px) {
            display: none;
          }
        }
        .error {
          color: $red;
          font-size: 14px;
        }
        .input__text__rounded {
          background: transparent;
          width: 400px;
          display: block;
          @media screen and (max-width: 960px) {
            width: 100%;
          }
        }
        .cta {
          @media screen and (max-width: 960px) {
            width: 100%;
          }
        }
        .xs {
          margin-left: auto;
          text-decoration: none;
          margin-top: 8px;
          cursor: pointer;
          &:hover {
            color: $orange;
          }
        }
        .error {
          margin-top: 8px;
          .error-msg {
            font-size: 14px;
            color: $red;
            list-style-type: none;
          }
          .resend-email {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 960px) {
              flex-direction: column;
              align-items: center;
            }
            .resend {
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                color: $orange;
              }
            }
          }
        }
      }
    }
    .divider {
      width: 2px;
      height: 392px;
      border: solid 1px $white;
      margin: 0 56px;
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    .register {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 400px;
      @media screen and (max-width: 960px) {
        width: 100%;
        align-items: center;
      }
      h3 {
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
      .btn-register:hover {
        color: $orange;
      }
    }
  }
}
</style>
