import api from './api';
import Cookie from 'js-cookie';

// retrieve XSRF Token only if it is not set already

export default {
  getCookie() {
    let token = Cookie.get("XSRF-TOKEN");

    if (token) {
      return new Promise(resolve => {
        resolve(token);
      });
    }

    return api.get("/csrf-cookie");
  }
};
