<template>
  <div class="infobox-header">
    <div class="user">
      <div class="user-icon">
        <img :src="avatarImage" alt="Avatar" />
        <div class="status-indicator" :class="statusIndicator"></div>
      </div>
      <p>{{ username }}</p>
    </div>
    <router-link class="row" to="/Profile">
      <img class="icon" src="@/assets/icons/edit_dark.svg" alt="Edit" />
      <p>Profil Bearbeiten</p>
    </router-link>
    <div class="row">
      <img class="icon" src="@/assets/icons/add-person_dark.svg" alt="Add" />
      <p>Freunde einladen</p>
    </div>

    <div class="seperator"></div>

    <div class="row" @click="logout">
      <img class="icon" src="@/assets/icons/logout_dark.svg" alt="Logout" />
      <p>Logout</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoboxHeader",
  data() {
    return {
      username: this.$store.getters.USER.username,
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          localStorage.clear();
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    avatarImage() {
      return require("@/assets/icons/" + this.$store.getters.USER.avatar);
    },
    statusIndicator() {
      return "online";
      // const status = this.$store.state.user.status;

      //  switch (status) {
      //   case 0:
      //     return "online";
      //   case 1:
      //     return "busy";
      //   case 2:
      //     return "dontDisturb";
      //   default:
      //     return "online";
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.infobox-header {
  cursor: default;
  position: absolute;
  padding: 12px 14px;
  background-color: #d8d8d8;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  right: 0px;
  top: 64px;
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    bottom: 100%;
    right: 12px;
    content: "";
    transform: rotate(45deg);
    margin-bottom: -12px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px 4px $orange;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: 960px) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    cursor: pointer;
    .icon {
      width: 28px;
      margin-left: 4px;
      margin-right: 14px;
    }
    p {
      color: $dark-navy-blue;
      white-space: nowrap;
    }
  }
  .user {
    margin-bottom: 12px;
    display: flex;
    @media screen and (max-width: 960px) {
      margin-bottom: 20px;
    }
    .user-icon {
      position: relative;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      margin-right: 14px;
      img {
        width: 100%;
        height: 100%;
      }
      .status-indicator {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        border: 2px solid $navy-blue;
      }
      .online {
        background-color: $green;
      }
      .busy {
        background-color: $yellow;
      }
      .dontDisturb {
        background-color: $red;
      }
    }
    p {
      color: $dark-navy-blue;
    }
  }
  .seperator {
    margin: 6px 0;
    width: 100%;
    height: 1px;
    background-color: #bfbfbf;
  }
}
</style>
