<template>
  <header>
    <flash-message class="flash-message"></flash-message>
    <router-link to="/Home">
      <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
    </router-link>
    <nav>
      <router-link to="/Home">Home</router-link>
      <router-link to="/SocialHub"
        >Social Hub <span class="notification" v-if="newMessages || friendRequests.length">({{ newMessages + friendRequests.length }})</span></router-link
      >
      <router-link to="/Teamee"
        >Team Me! <span class="notification" v-if="teamInvites.length">({{ teamInvites.length }})</span></router-link
      >
      <div class="user-icon" v-click-outside="hideInfobox">
        <img :src="avatarImage" alt="Avatar" @click="toggleInfobox" />
        <div class="status" :class="statusIndicator"></div>
        <InfoboxHeader v-show="showInfobox" />
      </div>
    </nav>
  </header>
</template>

<script>
require("vue-flash-message/dist/vue-flash-message.min.css");
import InfoboxHeader from "@/components/InfoboxHeader.vue";

export default {
  name: "AppHeader",
  components: {
    InfoboxHeader,
  },
  data() {
    return {
      showInfobox: false,
    };
  },
  methods: {
    toggleInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    hideInfobox() {
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
  },
  computed: {
    avatarImage() {
      return require("@/assets/icons/" + this.$store.getters.USER.avatar);
    },
    statusIndicator() {
      return "online";
      // if(this.$store.state.userData.status === 0){
      //   return "online";
      // }
      // else if(this.$store.state.userData.status === 1){
      //   return "busy";
      // }
      // else if(this.$store.state.userData.status === 2){
      //   return "dontDisturb";
      // } else {
      //   return "";
      // }
    },
    friendRequests() {
      return this.$store.getters.MY_FRIEND_REQUESTS;
    },
    teamInvites() {
      return this.$store.getters.MY_TEAM_INVITES;
    },
    newMessages() {
      return this.$store.getters.NEW_MESSAGES;
    },
  },
  watch: {
    // Close Infobox when Page Change occurs
    $route() {
      this.showInfobox = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

header {
  background-color: $navy-blue;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 40px 48px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  @media screen and (max-width: 960px) {
    padding: 20px 24px;
  }
  .logo__large {
    @media screen and (max-width: 960px) {
      height: 48px;
    }
  }
  nav {
    display: flex;
    align-items: center;
    .notification {
      color: $orange;
    }
    a {
      text-decoration: none;
      font-size: 20px;
      margin-right: 40px;
      &:hover {
        text-decoration: underline;
      }
      &:last-of-type {
        margin-right: 80px;
      }
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    .user-icon {
      position: relative;
      cursor: pointer;
      border-radius: 100%;
      width: 44px;
      height: 44px;
      img {
        width: 100%;
        height: 100%;
        &:active {
          filter: brightness(50%);
        }
      }
      .status {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        border: 2px solid $navy-blue;
      }
      .online {
        background-color: $green;
      }
      .busy {
        background-color: $yellow;
      }
      .dontDisturb {
        background-color: $red;
      }
    }
  }
}
</style>
