import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersistence from "vuex-persist";
import api from "@/assets/api/api";
import csrf from "@/assets/api/csrf";
import Echo from "laravel-echo";
import router from "../router";

const vuexLocal = new VuexPersistence({
  key: "teamee-vuex",
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    acceptCookies: null,
    acceptGACookies: null,

    // popular Games
    popularGames: [],

    // store Game Data (e.g bg_image, title ...)
    myGames: [],

    // teamee Requests
    teamee_requests: null,

    // Requests Filter Mode: all/lfg/lfm
    // 0=all, 1=lfg, 2=lfm
    teameeRequestsFilter: 0,

    // Team Invites
    team_invitations: [],

    // store current teamee Request Data (clicked on TeameeRequests.vue)
    teamee_request_data: null,

    // store current Chat Room Data (Chat Member Name, ID)
    current_chat_room_data: null,

    // Friendlist
    myFriends: [],

    // blocked Users
    blockedUsers: [],

    // Chat Rooms
    myChatRooms: [],

    // New Messages
    newMessages: 0,

    // New Group Messages
    newGroupMessages: 0,

    // Team
    myTeam: null,

    // Friend Requests
    friend_requests: [],

    // Register User Data
    registrationData: [
      {
        // Avatar ID
        avatarID: "0",
      },
      [
        // Games
      ],
    ],

    // User Data will be stored after Login
    user: null,

    // store Teamee Request Data when adding a new Game
    gameConfigs: {
      game_id: "",
      search_mode: "",
      player_count: null,
      platform: "",
      language: "",
      skill_tier: "",
      game_mode: "",
      description: "",
    },

    // Components
    homeComponent: "Home-Requests",
    registerComponent: "Register1",
    socialHubComponent: "AllChatRooms",
    profileComponent: "Profile-Home",
    teameeComponent: "Teamee1",
    legalComponent: "LegalNotice",
    showResendEmailComponent: false,
    legalOpen: false,
  },
  mutations: {
    SET_COOKIES(state, payload) {
      state.acceptCookies = payload;
    },
    SET_GA_COOKIES(state, payload) {
      state.acceptGACookies = payload;
    },

    // User Data
    SET_USER(state, payload) {
      state.user = payload;
    },

    // Update Avatar
    SET_USER_AVATAR(state, payload) {
      state.user.avatar = payload;
    },

    SET_USER_MY_GAMES(state, payload) {
      state.user.my_games = payload;
    },

    SET_TEAMEE_REQUESTS(state, payload) {
      state.teamee_requests = payload;
    },

    SET_TEAM_INVITES(state, payload) {
      state.team_invitations = payload;
    },

    // push new teamee Requests to teamme_requests Array
    ADD_TEAMEE_REQUESTS(state, payload) {
      let newRequests = payload.data.data;
      let game_id = newRequests[0].game_id;
      let newUrl = payload.data.next_page_url;
      state.teamee_requests[game_id].next_page_url = newUrl;

      for (let index = 0; index < newRequests.length; index++) {
        const element = newRequests[index];
        state.teamee_requests[game_id].data.push(element);
      }
    },

    // change Requests Display Mode
    SET_TEAMEE_REQUESTS_FILTER(state, payload) {
      state.teameeRequestsFilter = payload;
    },

    // set Chat Room Data
    SET_CHAT_ROOM_DATA(state, payload) {
      state.current_chat_room_data = payload;
    },

    // popular Games
    SET_POPULAR_GAMES(state, payload) {
      state.popularGames = payload;
    },

    // Games selected by User
    SET_MY_GAMES(state, payload) {
      state.myGames = payload;
    },

    // Friendlist
    SET_MY_FRIENDS(state, payload) {
      state.myFriends = payload;
    },

    // Blocked Users
    SET_BLOCKED_USERS(state, payload) {
      state.blockedUsers = payload;
    },

    // Chat Rooms
    SET_MY_CHAT_ROOMS(state, payload) {
      state.myChatRooms = payload;
    },

    // set Current Messages Count
    SET_NEW_MESSAGES_COUNT(state, payload) {
      state.newMessages = payload;
    },

    // Decrease Messages Count
    DECREASE_NEW_MESSAGES_COUNT(state, payload) {
      let result = state.newMessages - payload;
      state.newMessages = result;
    },

    // Team Data
    SET_MY_TEAM(state, payload) {
      state.myTeam = payload;
    },

    // remove Friend Request
    REMOVE_FRIEND_REQUEST(state, payload) {
      state.friend_requests.splice(payload, 1);
    },

    // remove Friend
    REMOVE_FRIEND(state, payload) {
      state.myFriends.splice(payload, 1);
    },

    // remove Chat Room
    REMOVE_CHAT_ROOM(state, payload) {
      state.myChatRooms.splice(payload, 1);
    },

    // Add to User's Registration Data
    ADD_REGISTRATION_DATA_GAMES(state, payload) {
      state.registrationData[1].push(payload);
    },

    // Remove from User's Registration Data
    REMOVE_REGISTRATION_DATA_GAMES(state, payload) {
      const i = state.registrationData[1].map((item) => item.gameID).indexOf(payload.gameID);
      state.registrationData[1].splice(i, 1);
    },

    // Update User's Registration Data (Avatar)
    SET_REGISTRATION_DATA(state, payload) {
      state.registrationData[payload.index].avatarID = payload.avatarID;
    },

    // set Friend Requests
    SET_FRIEND_REQUESTS(state, payload) {
      state.friend_requests = payload;
    },

    // Components
    SET_HOME_COMPONENT: (state, payload) => {
      state.homeComponent = payload;
    },
    SET_REGISTER_COMPONENT: (state, payload) => {
      state.registerComponent = payload;
    },
    SET_SOCIALHUB_COMPONENT: (state, payload) => {
      state.socialHubComponent = payload;
    },
    SET_PROFILE_COMPONENT: (state, payload) => {
      state.profileComponent = payload;
    },
    SET_TEAMEE_COMPONENT: (state, payload) => {
      state.teameeComponent = payload;
    },
    SET_LEGAL_COMPONENT: (state, payload) => {
      state.legalComponent = payload;
    },
  },
  actions: {
    // get the 20 most popular multiplayer Games from 01.08.2020 - 31.12.2020
    getPopularGames({ commit }) {
      axios.get(`https://api.rawg.io/api/games?key=34416eb7c9e34bbbb81b9890ca4ba394&dates=2020-08-01,2020-12-31&ordering=-added&tags=7`).then((response) => {
        commit("SET_POPULAR_GAMES", response.data.results);
      });
    },

    // get games data, selected by User
    getMyGames({ commit, state }) {
      if (state.user.my_games !== null) {
        const IDs = state.user.my_games;
        let payload = [];

        for (let index = 0; index < IDs.length; index++) {
          const element = IDs[index];

          // Axios Call with specific game ID's
          axios.get(`https://api.rawg.io/api/games/${element}?key=34416eb7c9e34bbbb81b9890ca4ba394`).then((response) => {
            payload.push(response.data);
          });
        }
        commit("SET_MY_GAMES", payload);
      }
    },

    // get User's Friendlist
    getMyFriends({ commit }) {
      // api request for friendlist should be inserted here

      api.get("/friends/all").then((response) => {
        commit("SET_MY_FRIENDS", response.data);
      });
    },

    /*
    --------------------   Register and Authorizing User   -------------------- 
    */
    // Check if User is Authorized
    async auth() {
      return api.get("/user");
    },

    // Register User
    async register(context, payload) {
      await csrf.getCookie();
      return api.post("/register", payload);
    },

    // Login User
    async login(context, payload) {
      await csrf.getCookie();
      return api.post("/login", payload);
    },

    // Logout User
    async logout({ getters }) {
      await csrf.getCookie();
      window.Echo.leave("user." + getters.USER.id); // disconnect from Pusher Channel
      return api.post("/logout");
    },

    // Add Avatar and Games in Registration Process
    async completeRegistration(context, payload) {
      const avatarID = payload[0].avatarID;
      let myGames = [];

      // Store all selected Game IDs
      for (let index = 0; index < payload[1].length; index++) {
        myGames.push(payload[1][index].gameID);
      }

      // get corresponding Avatar Name
      let avatar = null;
      switch (avatarID) {
        case 0:
          avatar = "avatar_batman.svg";
          break;
        case 1:
          avatar = "avatar_heisenberg.svg";
          break;
        case 2:
          avatar = "avatar_santa.svg";
          break;
        case 3:
          avatar = "avatar_wrestler.svg";
          break;
        case 4:
          avatar = "avatar_sloth.svg";
          break;
        case 5:
          avatar = "avatar_hipster.svg";
          break;
        case 6:
          avatar = "avatar_jason.svg";
          break;
        case 7:
          avatar = "avatar_zorro.svg";
          break;
        case 8:
          avatar = "avatar_pirate.svg";
          break;
        default:
          avatar = "avatar_batman.svg";
          break;
      }

      const data = { avatar, myGames };

      // send data
      await csrf.getCookie();
      return api.patch("/complete_registration", data);
    },

    /*
    --------------------   Email Verification   -------------------- 
    */
    // resend Email for Account Activation
    resendEmail() {
      return api.get("/email/resend");
    },

    // resend Email for Account Activation when user is not authorized
    // => User has to enter Username/Email and Password which will be the payload
    async resendWithEmail(context, payload) {
      await csrf.getCookie();
      return api.post("/email/resendWithEmail", payload);
    },

    /*
    --------------------   Forgot Password   -------------------- 
    */
    // Reset Password Request
    async sendResetPasswordRequest(context, payload) {
      await csrf.getCookie();
      return api.post("/password/email", payload);
    },

    // reset Users Password with token and credentials
    async resetPassword(context, payload) {
      await csrf.getCookie();
      return api.post("/password/reset", payload);
    },

    /*
    --------------------   User Profile Settings   -------------------- 
    */
    // change User Avatar
    async setAvatar(context, payload) {
      await csrf.getCookie();
      return api.patch("/avatar", payload);
    },

    async changePassword(context, payload) {
      await csrf.getCookie();
      return api.patch("change_password", payload);
    },

    async deleteAccount(context, payload) {
      await csrf.getCookie();
      return api.post("delete_account", payload);
    },

    /*
    --------------------   Teamee Requests   -------------------- 
    */
    async addTeameeRequest({ state }) {
      await csrf.getCookie();
      return api.post("teamee_request/add", state.gameConfigs);
    },

    async deleteTeameeRequest(context, payload) {
      await csrf.getCookie();
      return api.post("teamee_request/delete", payload);
    },

    async getTeameeRequests(context, payload) {
      await csrf.getCookie();
      return api.post("teamee_request/all", payload);
    },

    async loadMoreRequests(context, payload) {
      const page = payload.url.charAt(payload.url.length - 1);
      await csrf.getCookie();
      return api.post(`teamee_request/all?page=${page}`, payload);
    },

    /*
    --------------------   Friends and Friend Requests   -------------------- 
    */
    async fetchUser(context, payload) {
      await csrf.getCookie();
      return api.post("user/search", payload);
    },

    async addFriend(context, payload) {
      await csrf.getCookie();
      return api.post("friends/add", payload);
    },

    async deleteFriend(context, payload) {
      await csrf.getCookie();
      return api.post("friends/delete", payload);
    },

    async getFriendRequests({ commit }) {
      await api.get("friends/requests").then((response) => {
        commit("SET_FRIEND_REQUESTS", response.data);
        return;
      });
    },

    async acceptFriendRequest(context, payload) {
      await api.post("friends/accept", payload);
    },

    async declineFriendRequest(context, payload) {
      await api.post("friends/decline", payload);
    },

    async blockUser(context, payload) {
      await csrf.getCookie();
      return api.post("friends/block", payload);
    },

    getBlockedUsers({ commit }) {
      return api.get("/friends/blockedUsers").then((response) => {
        commit("SET_BLOCKED_USERS", response.data);
      });
    },

    async unblockUser(context, payload) {
      await csrf.getCookie();
      return api.post("friends/unblock", payload);
    },

    /*
    --------------------   Chat Rooms and Messages   -------------------- 
    */
    getChatRooms({ commit }) {
      return api.get("chat/rooms").then((response) => {
        commit("SET_MY_CHAT_ROOMS", response.data);
        let messages = 0;
        for (let i = 0; i < response.data.length; i++) {
          messages += response.data[i].unread_messages.length;
        }
        commit("SET_NEW_MESSAGES_COUNT", messages);
      });
    },

    getChatMessages(context, payload) {
      return api.get("chat/room/" + payload + "/messages");
    },

    async loadMoreChatMessages(context, payload) {
      const page = payload.url.charAt(payload.url.length - 1);
      await csrf.getCookie();
      return api.get(`chat/room/${payload.chat_room_id}/messages?page=${page}`);
    },

    async sendMessage(context, payload) {
      await csrf.getCookie();
      return api.post("chat/room/" + payload.roomId + "/message", {
        message: payload.message,
        receivers: payload.receivers,
      });
    },

    async deleteMessage(context, payload) {
      await csrf.getCookie();
      return api.delete("message/" + payload);
    },

    async createNewChatRoom(context, payload) {
      await csrf.getCookie();
      return api.post("chat/rooms/new", payload);
    },

    async deleteChatRoom(context, payload) {
      await csrf.getCookie();
      return api.post("chat/room/" + payload.roomId + "/delete");
    },

    async receiveNotifications({ state, dispatch }) {
      window.Pusher = require("pusher-js");
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "21e8c46664e0574ecddc",
        cluster: "eu",
        forceTLS: true,
        encrypted: true,
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              api
                .post("/broadcasting/auth", {
                  socket_id: socketId,
                  channel_name: channel.name,
                })
                .then((response) => {
                  callback(false, response.data);
                })
                .catch((error) => {
                  callback(true, error);
                });
            },
          };
        },
      });
      window.Echo.private(`user.${state.user.id}`)
        .listen("NewChatMessage", (notification) => {
          // only send Notification and add to New Messages Counter, if User is not in Chat Room, where this Message is being sent to
          if (
            !(
              router.history.current.name == "SocialHub" &&
              state.socialHubComponent == "Chat" &&
              state.current_chat_room_data.chatRoomId == notification.chatMessage.chat_room_id
            )
          ) {
            // INFO: This should have a rework
            // => distinguish between new Group Message or Normal Message and increase Msg. Counter accordingly
            // => best Solution would be without DB Interaction
            // => for now, every time a new Message is received, Team Data and Group Chat Data are being fetched from DB
            dispatch("getMyTeam"); // get Chat Rooms and update New Messages Counter
            dispatch("getChatRooms"); // get Chat Rooms and update New Messages Counter
            Vue.prototype.$flashStorage.flash(`Neue Nachricht von ${notification.author.username}: ${notification.chatMessage.message}`, "info", {
              timeout: 3000,
            });
          }
        })
        .listen("NewFriendRequest", (notification) => {
          state.friend_requests.push(notification);
          Vue.prototype.$flashStorage.flash(`Neue Freundschaftsanfrage von: ${notification.requesting_username}!`, "info", { timeout: 3000 });
        })
        .listen("NewTeamRequest", (notification) => {
          dispatch("getMyTeam");
          state.team_invitations.push(notification);
          Vue.prototype.$flashStorage.flash(`Du hast eine neue Teameinladung von: ${notification.requesting_username} erhalten!`, "info", { timeout: 3000 });
        });
    },

    async markMessageAsRead(context, payload) {
      await csrf.getCookie();
      return api.post("chat/room/messages_read", payload);
    },

    /*
    --------------------   Team Data, Invites, Requests and Members   -------------------- 
    */
    getMyTeam({ commit, dispatch }) {
      api.get("team/get").then((response) => {
        commit("SET_MY_TEAM", response.data);
        // if User has no Team, get Team Invites
        if (response.data == []) {
          dispatch("getTeamInvitations");
        }
      });
    },

    async createTeam(context, payload) {
      await csrf.getCookie();
      return api.post("team/create", payload);
    },

    async deleteTeam(context, payload) {
      await csrf.getCookie();
      return api.post("team/delete", payload);
    },

    async sendTeamInvitation(context, payload) {
      await csrf.getCookie();
      return api.post("team/teamInvitation", payload);
    },

    getTeamInvitations({ commit }) {
      api.get("team/getInvitations").then((response) => {
        commit("SET_TEAM_INVITES", response.data);
      });
    },

    async acceptTeamInvite(context, payload) {
      await csrf.getCookie();
      return api.post("team/acceptInvitation", payload);
    },

    async declineTeamInvite(context, payload) {
      await csrf.getCookie();
      return api.post("team/declineInvitation", payload);
    },

    async deleteTeamMember(context, payload) {
      await csrf.getCookie();
      return api.post("team/deleteMember", payload);
    },
  },
  modules: {},
  getters: {
    COOKIES: (state) => {
      return state.acceptCookies;
    },
    GA_COOKIES: (state) => {
      return state.acceptGACookies;
    },

    // User Data
    USER: (state) => {
      return state.user;
    },

    // Registration Data
    REGISTRATION_DATA: (state) => {
      return state.registrationData;
    },

    // User's Games List
    MY_GAMES: (state) => {
      return state.myGames;
    },

    // User's Chat Rooms
    MY_CHAT_ROOMS: (state) => {
      return state.myChatRooms;
    },

    // User's Team
    MY_TEAM: (state) => {
      return state.myTeam;
    },

    // Blocked Users
    BLOCKED_USERS: (state) => {
      return state.blockedUsers;
    },

    // User's TeameeRequests
    MY_TEAMEE_REQUESTS: (state) => {
      return state.teamee_requests;
    },

    // Requests Display Mode: all/lfg/lfm
    TEAMEE_REQUESTS_FILTER: (state) => {
      return state.teameeRequestsFilter;
    },

    // User's Team Invites
    MY_TEAM_INVITES: (state) => {
      return state.team_invitations;
    },

    // Friend Requests
    MY_FRIEND_REQUESTS: (state) => {
      return state.friend_requests;
    },

    // New Messages Count
    NEW_MESSAGES: (state) => {
      return state.newMessages;
    },

    // New Group Messages Count
    NEW_GROUP_MESSAGES: (state) => {
      return state.newGroupMessages;
    },

    // current Chat Room Data
    CURRENT_CHAT_ROOM_DATA: (state) => {
      return state.current_chat_room_data;
    },

    // Components
    HOMECOMPONENT: (state) => {
      return state.homeComponent;
    },
    REGISTERCOMPONENT: (state) => {
      return state.registerComponent;
    },
    SOCIALHUBCOMPONENT: (state) => {
      return state.socialHubComponent;
    },
    PROFILECOMPONENT: (state) => {
      return state.profileComponent;
    },
    TEAMEECOMPONENT: (state) => {
      return state.teameeComponent;
    },
    LEGALCOMPONENT: (state) => {
      return state.legalComponent;
    },
  },
  plugins: [vuexLocal.plugin],
});
