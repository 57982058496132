import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import Vuelidate from "vuelidate";
import VueFlashMessage from "vue-flash-message";
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

// Check if User has clicked outside of a specific Element
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// Register Vuelidate Plugin
Vue.use(Vuelidate);

Vue.use(VueFlashMessage);

if (document.cookie.indexOf("acceptGACookies") > -1) {
  // User accepted Tracking
  Vue.use(VueAnalytics, {
    id: "UA-188337201-1",
    disabled: false,
    set: [{ field: "anonymizeIp", value: true }],
    router,
  });
} else {
  // User only accepted essential Cookies
  Vue.use(VueAnalytics, {
    id: "UA-188337201-1",
    disabled: true,
    set: [{ field: "anonymizeIp", value: true }],
    router,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
