<template>
  <footer>
    <div class="links">
      <router-link to="/Legal">Impressum</router-link> | <router-link to="/Legal">Datenschutz</router-link> |
      <router-link to="/Licenses">Lizenzen</router-link>
    </div>
    <div class="rawg-link">Game Data powered by <a target="_blank" href="https://rawg.io">rawg.io</a></div>
  </footer>
</template>

<script>
export default {
  name: "AppFooterRegistration",
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  width: 100%;
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  .links {
    a {
      padding: 0 4px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .rawg-link {
    position: absolute;
    bottom: 6px;
    text-align: center;
    font-size: 12px;
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>
