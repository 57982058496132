import Vue from "vue";
import VueRouter from "vue-router";
import RegisterAndLogin from "../views/RegisterAndLogin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "RegisterAndLogin",
    component: RegisterAndLogin,
  },
  {
    path: "/ForgotPassword-1",
    name: "ForgotPassword-1",
    meta: {
      guestOnly: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ForgotPassword-1" */ "../views/ForgotPassword-1.vue"),
  },
  {
    path: "/ForgotPassword-2",
    name: "ForgotPassword-2",
    meta: {
      guestOnly: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ForgotPassword-2" */ "../views/ForgotPassword-2.vue"),
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    meta: {
      guestOnly: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ResetPassword" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    meta: {
      guestOnly: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Register" */ "../views/Register.vue"),
  },
  {
    path: "/Home",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/SocialHub",
    name: "SocialHub",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "SocialHub" */ "../views/SocialHub.vue"),
  },
  {
    path: "/Teamee",
    name: "Teamee",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Teamee" */ "../views/Teamee.vue"),
  },
  {
    path: "/Profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/Legal",
    name: "Legal",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Legal" */ "../views/Legal.vue"),
  },
  {
    path: "/Licenses",
    name: "Licenses",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Licenses" */ "../views/Licenses.vue"),
  },
  {
    path: "/ThankYou",
    name: "ThankYou",
    meta: {
      deletedUserOnly: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ThankYou" */ "../views/ThankYou.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Check if User is autherized to visit specific Views
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem("auth") !== "true") {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires guestOnly, check if logged in
    // if so, redirect to Home page.
    if (localStorage.getItem("auth") == "true") {
      next({
        path: "/Home",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.deletedUserOnly)) {
    // this route requires deletedUserOnly, check if thankyou is "true"
    // if so, redirect to Thank You page.
    if (localStorage.getItem("thankyou") !== "true") {
      next({
        path: "/Home",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
