<template>
  <footer>
    <div class="desktop">
      <div class="desktop">
        <div class="links">
          <router-link to="/Legal">Impressum</router-link> | <router-link to="/Legal">Datenschutz</router-link> |
          <router-link to="/Licenses">Lizenzen</router-link>
        </div>
        <div class="rawg-link">Game Data powered by <a target="_blank" href="https://rawg.io">rawg.io</a></div>
      </div>
    </div>

    <div class="mobile">
      <router-link to="/Home">
        <svg height="32px" viewBox="0 0 64 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>home_white</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-29.000000, -317.000000)" fill="#FFFFFF" fill-rule="nonzero">
              <g id="home_white" transform="translate(29.000000, 317.000000)">
                <polygon id="Path" points="7 37 7 58 26 58 26 43 38 43 38 58 57 58 57 37 32 15"></polygon>
                <polygon id="Path" points="57 22 57 8 48 8 48 14 32 0 0 28.9 4.1 33.1 32 8.2 59.9 33.1 64 28.9"></polygon>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
      <router-link class="socialhub" to="/SocialHub">
        <div class="notification-count" v-if="friendRequests.length || newMessages > 0">{{ newMessages + friendRequests.length }}</div>

        <svg
          v-if="friendRequests.length || newMessages > 0"
          width="64px"
          height="45px"
          viewBox="0 0 64 45"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>chat_grey_msg</title>
          <defs>
            <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
              <stop stop-color="#FF6E00" offset="0%"></stop>
              <stop stop-color="#FF1D00" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-416.000000, -486.000000)" fill-rule="nonzero">
              <g id="chat_grey_msg" transform="translate(416.000000, 486.000000)">
                <path
                  v-if="newMessages > 0"
                  d="M15,35.5 C17.2,36.1 19.5,36.5 22,36.5 C34.2,36.5 44,28.4 44,18.5 C44,8.6 34.2,0.5 22,0.5 C9.8,0.5 0,8.6 0,18.5 C0,24.1 3.1,29.2 8,32.5 C8,32.5 8,35.1 5.9,37.7 C5.3,38.4 5.9,39.5 6.8,39.3 C12.2,38.5 15,35.5 15,35.5 Z"
                  id="Path"
                  fill="url(#linearGradient-1)"
                ></path>
                <path
                  v-else
                  d="M15,35.5 C17.2,36.1 19.5,36.5 22,36.5 C34.2,36.5 44,28.4 44,18.5 C44,8.6 34.2,0.5 22,0.5 C9.8,0.5 0,8.6 0,18.5 C0,24.1 3.1,29.2 8,32.5 C8,32.5 8,35.1 5.9,37.7 C5.3,38.4 5.9,39.5 6.8,39.3 C12.2,38.5 15,35.5 15,35.5 Z"
                  id="Path"
                  fill="#808080"
                ></path>
                <path
                  v-if="friendRequests.length"
                  d="M64,24 C64,14.4 57.3,6.6 43.4,6 C46.3,9.5 48,13.8 48,18.5 C48,28.1 40.7,36.3 30.5,39.3 C33.8,41 37.8,42 42,42 C44.5,42 46.8,41.6 49,41 C49,41 51.8,44 57.1,44.8 C58,44.9 58.6,43.9 58,43.2 C56,40.6 56,38 56,38 C60.9,34.7 64,29.6 64,24 Z"
                  id="Path"
                  fill="url(#linearGradient-1)"
                ></path>
                <path
                  v-else
                  d="M64,24 C64,14.4 57.3,6.6 43.4,6 C46.3,9.5 48,13.8 48,18.5 C48,28.1 40.7,36.3 30.5,39.3 C33.8,41 37.8,42 42,42 C44.5,42 46.8,41.6 49,41 C49,41 51.8,44 57.1,44.8 C58,44.9 58.6,43.9 58,43.2 C56,40.6 56,38 56,38 C60.9,34.7 64,29.6 64,24 Z"
                  id="Path"
                  fill="#808080"
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <svg v-else height="32px" viewBox="0 0 64 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>chat_white</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-416.000000, -327.000000)" fill="#FFFFFF" fill-rule="nonzero">
              <g id="chat_white" transform="translate(416.000000, 327.000000)">
                <path
                  d="M15,35.5 C17.2,36.1 19.5,36.5 22,36.5 C34.2,36.5 44,28.4 44,18.5 C44,8.6 34.2,0.5 22,0.5 C9.8,0.5 0,8.6 0,18.5 C0,24.1 3.1,29.2 8,32.5 C8,32.5 8,35.1 5.9,37.7 C5.3,38.4 5.9,39.5 6.8,39.3 C12.2,38.5 15,35.5 15,35.5 Z"
                  id="Path"
                ></path>
                <path
                  d="M64,24 C64,14.4 57.3,6.6 43.4,6 C46.3,9.5 48,13.8 48,18.5 C48,28.1 40.7,36.3 30.5,39.3 C33.8,41 37.8,42 42,42 C44.5,42 46.8,41.6 49,41 C49,41 51.8,44 57.1,44.8 C58,44.9 58.6,43.9 58,43.2 C56,40.6 56,38 56,38 C60.9,34.7 64,29.6 64,24 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
      <router-link class="teamee" to="/Teamee">
        <div class="notification-count" v-if="teamInvites.length">{{ teamInvites.length }}</div>

        <svg
          v-if="teamInvites.length"
          width="100%"
          height="100%"
          viewBox="0 0 64 58"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
          <g id="Page-1">
            <g id="Desktop-HD">
              <g id="teamee_white">
                <circle id="Oval" cx="32" cy="23" r="12" style="fill:url(#_Linear1);" />
                <path
                  id="Path"
                  d="M37.5,38L26.5,38C19.1,38 13,44 13,51.4L13,58L51,58L51,51.4C51,44 44.9,38 37.5,38Z"
                  style="fill:url(#_Linear2);fill-rule:nonzero;"
                />
                <path
                  id="Path1"
                  serif:id="Path"
                  d="M16.1,21C16.4,18.9 17,16.9 18.1,15.1L18.1,15C18.6,14.1 19.2,13.3 19.8,12.6L19.9,12.5C20.5,11.8 21.2,11.1 21.9,10.5C22,10.4 22,10.4 22.1,10.3C22.8,9.7 23.6,9.2 24.5,8.8C24.6,8.8 24.7,8.7 24.8,8.7C25.1,8.6 25.3,8.4 25.6,8.3C24.4,4 20.4,0.8 15.7,1C10.1,1.2 5.8,5.8 6,11.3C6.2,16.8 10.7,21.1 16.1,21Z"
                  style="fill:url(#_Linear3);fill-rule:nonzero;"
                />
                <path
                  id="Path2"
                  serif:id="Path"
                  d="M21.2,34.8C18.2,32.1 16.3,28.3 16,24L11.3,24C5.1,24 0,29.4 0,35.9L0,46L9.9,46C11.6,40.7 15.8,36.5 21.2,34.8Z"
                  style="fill:url(#_Linear4);fill-rule:nonzero;"
                />
                <path
                  id="Path3"
                  serif:id="Path"
                  d="M39.2,8.7C39.3,8.7 39.4,8.8 39.5,8.8C40.3,9.2 41.1,9.8 41.9,10.3C42,10.4 42,10.4 42.1,10.5C42.8,11.1 43.5,11.8 44.1,12.5L44.2,12.6C44.8,13.3 45.4,14.1 45.9,15L45.9,15.1C46.9,16.9 47.6,18.9 47.9,21C53.3,21.1 57.9,16.8 58,11.3C58.2,5.8 53.9,1.2 48.3,1C43.6,0.8 39.5,4 38.3,8.3C38.7,8.5 38.9,8.6 39.2,8.7Z"
                  style="fill:url(#_Linear5);fill-rule:nonzero;"
                />
                <path
                  id="Path4"
                  serif:id="Path"
                  d="M52.6,24L48,24C47.7,28.3 45.8,32.1 42.8,34.8C48.2,36.5 52.4,40.7 54.1,46L64,46L64,35.9C64,29.4 58.9,24 52.6,24Z"
                  style="fill:url(#_Linear6);fill-rule:nonzero;"
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
            <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
            <linearGradient id="_Linear3" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
            <linearGradient id="_Linear4" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
            <linearGradient id="_Linear5" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
            <linearGradient id="_Linear6" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64,0,0,57.0089,0,29.4956)">
              <stop offset="0" style="stop-color:rgb(255,110,0);stop-opacity:1" />
              <stop offset="1" style="stop-color:rgb(255,29,0);stop-opacity:1" />
            </linearGradient>
          </defs>
        </svg>
        <svg v-else height="32px" viewBox="0 0 64 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>teamee_white</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-545.000000, -317.000000)" fill="#FFFFFF" fill-rule="nonzero">
              <g id="teamee_white" transform="translate(545.000000, 317.000000)">
                <circle id="Oval" cx="32" cy="23" r="12"></circle>
                <path d="M37.5,38 L26.5,38 C19.1,38 13,44 13,51.4 L13,58 L51,58 L51,51.4 C51,44 44.9,38 37.5,38 Z" id="Path"></path>
                <path
                  d="M16.1,21 C16.1,21 16.1,21 16.1,21 C16.4,18.9 17,16.9 18.1,15.1 C18.1,15.1 18.1,15.1 18.1,15 C18.6,14.1 19.2,13.3 19.8,12.6 C19.8,12.6 19.9,12.5 19.9,12.5 C20.5,11.8 21.2,11.1 21.9,10.5 C22,10.4 22,10.4 22.1,10.3 C22.8,9.7 23.6,9.2 24.5,8.8 C24.6,8.8 24.7,8.7 24.8,8.7 C25.1,8.6 25.3,8.4 25.6,8.3 C25.6,8.3 25.6,8.3 25.6,8.3 C24.4,4 20.4,0.8 15.7,1 C10.1,1.2 5.8,5.8 6,11.3 C6.2,16.8 10.7,21.1 16.1,21 Z"
                  id="Path"
                ></path>
                <path d="M21.2,34.8 C18.2,32.1 16.3,28.3 16,24 L11.3,24 C5.1,24 0,29.4 0,35.9 L0,46 L9.9,46 C11.6,40.7 15.8,36.5 21.2,34.8 Z" id="Path"></path>
                <path
                  d="M39.2,8.7 C39.3,8.7 39.4,8.8 39.5,8.8 C40.3,9.2 41.1,9.8 41.9,10.3 C42,10.4 42,10.4 42.1,10.5 C42.8,11.1 43.5,11.8 44.1,12.5 C44.1,12.5 44.2,12.6 44.2,12.6 C44.8,13.3 45.4,14.1 45.9,15 C45.9,15 45.9,15 45.9,15.1 C46.9,16.9 47.6,18.9 47.9,21 C47.9,21 47.9,21 47.9,21 C53.3,21.1 57.9,16.8 58,11.3 C58.2,5.8 53.9,1.2 48.3,1 C43.6,0.8 39.5,4 38.3,8.3 C38.3,8.3 38.3,8.3 38.3,8.3 C38.7,8.5 38.9,8.6 39.2,8.7 Z"
                  id="Path"
                ></path>
                <path
                  d="M52.6,24 L48,24 C47.7,28.3 45.8,32.1 42.8,34.8 C48.2,36.5 52.4,40.7 54.1,46 L64,46 L64,35.9 C64,29.4 58.9,24 52.6,24 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
      <router-link to="/Profile">
        <svg height="32px" viewBox="0 0 46 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>person_white</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-296.000000, -317.000000)" fill="#FFFFFF" fill-rule="nonzero">
              <g id="person_white" transform="translate(296.000000, 317.000000)">
                <circle id="Oval" cx="23" cy="15" r="15"></circle>
                <path d="M29.6,34 L16.4,34 C7.4,34 0,41.4 0,50.4 L0,64 L46,64 L46,50.4 C46,41.4 38.6,34 29.6,34 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MobileNavBar",
  computed: {
    friendRequests() {
      return this.$store.getters.MY_FRIEND_REQUESTS;
    },
    newMessages() {
      return this.$store.getters.NEW_MESSAGES;
    },
    teamInvites() {
      return this.$store.getters.MY_TEAM_INVITES;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

footer {
  @media screen and (max-width: 960px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 500;
  }
  .desktop {
    position: relative;
    width: 100%;
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 960px) {
      display: none;
    }
    .links {
      a {
        padding: 0 4px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .rawg-link {
      position: absolute;
      bottom: 6px;
      text-align: center;
      font-size: 12px;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .mobile {
    background-color: $dark-navy-blue;
    padding-bottom: 12px;
    width: 100%;
    height: 83px;
    display: flex;
    align-items: center;
    @media screen and (max-height: 667px) {
      // this should adjust Navbar on smaller Devices (iPhone X and smaller, since they have no Home Balken)
      height: 60px;
    }
    @media screen and (min-width: 960px) {
      display: none;
    }
    @media screen and (max-width: 620px) {
      padding-bottom: 24px;
    }
    a {
      width: 25%;
      height: 100%;
      text-align: center;
      svg {
        margin-top: 16px;
        height: 32px;
        @media screen and (max-width: 620px) {
          height: 24px;
        }
        g {
          fill: $grey;
        }
      }
      &:hover g {
        fill: $white;
      }
    }
    .socialhub,
    .teamee {
      position: relative;
      .notification-count {
        position: absolute;
        top: 4px;
        margin-left: 50%;
        transform: translateX(calc(-50% + 20px));
        font-size: 12px;
        background: $gradient;
        border-radius: 5px;
        padding: 0px 8px;
        color: $navy-blue;
        font-weight: 800;
      }
    }
    .router-link-active {
      g {
        fill: $white;
      }
    }
  }
}
</style>
