<template>
  <div class="resend-mail__wrapper">
    <div class="resend-email">
      <img src="@/assets/icons/close.svg" alt="Close" @click="hideInfobox" />
      <form>
        <input
          class="input__text__rounded"
          :class="{ input__text__rounded__danger: $v.nameOrMail.$error }"
          type="text"
          placeholder="Username oder E-Mail"
          title="Username oder E-Mail"
          v-model.trim="$v.nameOrMail.$model"
        />
        <input
          class="input__text__rounded"
          :class="{ input__text__rounded__danger: $v.password.$error }"
          type="password"
          placeholder="Password"
          title="Password"
          v-model.trim="$v.password.$model"
        />
        <div class="button cta button__cta" @click="sendEmail" :disabled="isLoading">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>E-Mail erneut senden</span>
        </div>
        <p v-if="errorMsg">{{ errorMsg }}</p>
        <ul v-if="errors.length">
          <li v-for="(item, index) in errors" :key="index">
            <p>{{ item[0] }}</p>
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "ResendEmailInfobox",
  data() {
    return {
      nameOrMail: "",
      password: "",
      isLoading: false,
      errors: [],
      errorMsg: "",
    };
  },
  methods: {
    // Resend Email Activation Link
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        this.$store
          .dispatch("resendWithEmail", { nameOrMail: this.nameOrMail, password: this.password })
          .then((response) => {
            this.isLoading = false;
            this.errorMsg = response.data.msg;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
      }
    },
    hideInfobox() {
      this.$store.state.showResendEmailComponent = false;
    },
  },
  validations: {
    nameOrMail: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.resend-mail__wrapper {
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0e1247b8;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 0 24px;
  }
  .resend-email {
    border-radius: 10px;
    border: 2px solid $orange;
    width: 600px;
    height: 400px;
    background-color: #101010;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    img {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 32px;
      cursor: pointer;
    }
    form {
      width: 400px;
      @media screen and (max-width: 960px) {
        width: 90%;
      }
      input {
        margin-bottom: 16px;
      }
      .button {
        margin-bottom: 12px;
      }
      p {
        line-height: 24px;
      }
    }
  }
}
</style>
